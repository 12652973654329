html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  background-color: #fff;
  color: #3c4858;
  margin: 0;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 300;
  line-height: 1.5em;
}

/* Font Style */
blockquote footer:before,
blockquote small:before {
  content: '\2014 \00A0';
}
small {
  font-size: 80%;
}
h1 {
  font-size: 3em;
  line-height: 1.15em;
}
h2 {
  font-size: 2.4em;
}
h3 {
  font-size: 1.825em;
  line-height: 1.4em;
  margin: 20px 0 10px;
}
h4 {
  font-size: 1.3em;
  line-height: 1.4em;
}
h5 {
  font-size: 1.25em;
  line-height: 1.4em;
  margin-bottom: 15px;
}
h6 {
  font-size: 1em;
  text-transform: uppercase;
  font-weight: 500;
}
body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 300;
  line-height: 1.5em;
}
blockquote p {
  font-style: italic;
}
a {
  color: #9c27b0;
  text-decoration: none;
}
a:hover,
a:focus {
  color: #89229b;
  text-decoration: none;
}
/* //----------/// */

* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
}

*:focus {
  outline: 0;
}

a:focus,
a:active,
button:active,
button:focus,
button:hover,
button::-moz-focus-inner,
input[type='reset']::-moz-focus-inner,
input[type='button']::-moz-focus-inner,
input[type='submit']::-moz-focus-inner,
select::-moz-focus-inner,
input[type='file'] > input[type='button']::-moz-focus-inner {
  outline: 0 !important;
}

label {
  font-size: 14px;
  line-height: 1.42857;
  color: #aaaaaa;
  font-weight: 400;
}

@media (max-width: 991px) {
  body,
  html {
    position: relative;
    overflow-x: hidden;
  }

  #bodyClick {
    height: 100%;
    width: 100%;
    position: fixed;
    opacity: 0;
    top: 0;
    left: auto;
    right: 260px;
    content: '';
    z-index: 9999;
    overflow-x: hidden;
  }
}
* {
  letter-spacing: normal !important;
}
